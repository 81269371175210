<template lang="pug">
.page.page-with-table.users-page
  template(v-if="isAdmin")
    .users-page-header
      .users-page-header__title Пользователи
      it-button(v-if="isAdmin" type="primary" @click="dialog = true") Добавить пользователя

    data-table.page__table.users-page__table(
      :loading="loading" :items="users" :headers="headers" modelName="user"
      :editApiMethod="editApiMethod" :editApiUrl="editApiUrl" @update:item="updateUser"
      :deleteApiMethod="deleteApiMethod" :deleteApiUrl="deleteApiUrl" @delete:item="deleteUser")
    user-form-modal(v-model="dialog" @user:create="addUser")
  
  forbidden-modal(:dialog="!isAdmin")
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import DataTable from '@/components/DataTable'
import UserFormModal from '@/components/UserFormModal'


export default {
  name: 'UsersPage',
  components: {DataTable, UserFormModal},
  data() { 
    return {

      loading: false,
      users: [],
      headers: [
        {title: 'id', value: 'id', sortable: true, readonly: true},
        {title: 'name', value: 'name', sortable: true},
        {title: 'active', value: 'active', boolean: true},
        {title: 'isAdmin', value: 'isAdmin', boolean: true},
        {title: 'password', value: 'password', inputType: 'password'}
      ],

      editApiMethod: 'patch',
      editApiUrl: '/users',
      deleteApiMethod: 'delete',
      deleteApiUrl: '/users',

      dialog: false
    }
  },

  methods: {
    async getUsers() {
      this.loading = true

      try {
        const {data} = await this.$api.get('/users')
        this.users = data.result.data
      } catch (err) {
        console.log(err)
      }

      this.loading = false
    },


    addUser(user) {
      this.users = [...this.users, user]
    },
    updateUser(user) {
      const index = this.users.findIndex( elem => elem.id === user.id )
      this.users[index] = {...user}
    },
    deleteUser(id) {
      const index = this.users.findIndex( elem => elem.id === id )
      this.users.splice(index, 1)
    }
  },


  mounted() {
    this.getUsers()
  }
}
</script>