<template lang="pug">
it-modal.user-form-modal(v-model="modelValue")
  template(#header) 
    .user-form-modal__header Добавить пользователя

  template(#body)
    form.user-form-modal__content.user-form(id="user" @submit.prevent="submit")
      label.user-form-label
        span.user-form-label__text Имя(логин)
        it-input(v-model="user.name")
      label.user-form-label
        span.user-form-label__text Пароль
        it-input(v-model="user.password")
      label.user-form-label.user-form-label_switch
        span.user-form-label__text Админ
        it-switch(v-model="user.isAdmin")


  template(#actions)
    .user-form-modal__actions
      it-button.user-form-modal__actions-item(type="danger" outlined @click="$emit('update:modelValue', false)") Отменить
      it-button.user-form-modal__actions-item(type="primary" form="user" :laoding="loading") Создать
</template>

<style lang="sass" src="./index.sass"></style>


<script>
import emptyUser from '@/assets/emptyUser'


export default {
  name: 'UserFormModal',
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue', 'user:create'],
  data() {
    return {

      loading: false,
      user : {...emptyUser}

    }
  },

  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue)
      if ( !this.modelValue ) this.user = {...emptyUser}
    }
  },

  methods: {
    async submit() {
      if ( this.loading ) return
      this.loading = true

      try {
        const {data} = await this.$api.post('/users', {user: this.user})
        const user = data.result.user
        this.$notification.success('Успешно создано')
        this.$emit('update:modelValue', false)
        this.$emit('user:create', user)
      } catch(err) {
        console.log(err)
      }

      this.loading = false
    }
  }
}
</script>